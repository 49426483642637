<template>
  <v-container class="customers pb-8 fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>

    <HeadlineTableView
        :icon="'mdi-account'"
        :headline="'Kunden'"
        :button-icon="'mdi-account-plus'"
        :button-text="'Neuer Kunde'"
        :entity="'customer'"

        @buttonClick="openDialogCreateCustomer()"
    ></HeadlineTableView>

    <v-card flat class="rounded-xl pa-2 pa-sm-4">
      <v-row>
        <v-col cols="12" md="8">
          <Tab
              :tabs="['Liste','Archiv']"
              :register="register"
              @changed="register=$event"
          >
          </Tab>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field type="text" v-model="searchText" label="Suche Kunden..."
                        class="me-6 ps-6 pe-6 pt-2 pb-2 searchInput" @keyup="search"/>
        </v-col>
      </v-row>

      <div v-if="loaded">
        <TableView
            v-if="register === 0"
            :headLines="headLines"
            :rows="customersSearched"
            :archiveEnabled="true"
            :textNoResults="'Hier werden deine Kunden angezeigt.'"
            :entity="'customer'"
            @archiveEntries="archiveCustomers"
            @deleteEntries="deleteCustomers"
            @openDetail="openDetailCustomer"
        ></TableView>
        <TableView
            v-if="register === 1"
            :headLines="headLines"
            :rows="customersSearchedArchived"
            :textNoResults="'Hier werden deine archivierten Kunden angezeigt.'"
            :entity="'customer'"
            @deleteEntries="deleteCustomers"
            @openDetail="openDetailCustomer"
        ></TableView>
      </div>
      <Spinner v-else></Spinner>
      <CreateCustomer
          :dialog="dialog"
          :openAfterCreate="true"
          @hideDialog="hideDialog"
      ></CreateCustomer>
    </v-card>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const TableView = () => import("@/components/tableView/TableView");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const CreateCustomer = () => import("@/components/dialog/createDialogs/CreateCustomer");
const Spinner = () => import("@/components/generalUI/Spinner");
const Tab = () => import("@/components/generalUI/Tab");
const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
  name: 'Customers',
  components: {
    TableView,
    Message,
    HeadlineTableView,
    CreateCustomer,
    Spinner,
    Tab
  },
  computed: {
    ...mapGetters('customer', {
      customers: 'customers',
      customersSearched: 'customersSearched',
      customersSearchedArchived: 'customersSearchedArchived',
      customersArchived: 'customersArchived'
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      register: 0,
      message: msgObj(),
      searchText: '',
      headLines: [
        {text: "Vorname", sort: 'firstname'},
        {text: "Nachname", sort: 'lastname'},
        {text: "Status", sort: 'status'},
        {text: "E-Mail", sort: 'email'},
      ]
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('customer/getCustomers', {
        uid: res
      }).then(() => {
        if (this.$route.query.startTour) {
          this.loadedTour = true
        }
        this.loaded = true

        this.$store.dispatch('settings/getStatusForSelection', {
          uid: this.user.id
        }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  methods: {
    openDialogCreateCustomer() {
      this.dialog = true
    },
    openDetailCustomer(id) {
      this.$router.push("kunden/kunde/" + id)
    },
    hideDialog() {
      this.dialog = false
    },
    search() {
      if (this.register === 0) {
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.customers}
        ).then((results) => {
          this.$store.dispatch('customer/setCustomersSearched', results)
        })
      } else if (this.register === 1) {
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.customersArchived}
        ).then((results) => {
          this.$store.dispatch('customer/setCustomersSearchedArchived', results)
        })
      }
    },
    async deleteCustomers(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('customer/deleteCustomer', {
          uid: this.user.id,
          customerId: id,
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            if (ids.length === 1) {
              this.message = success("Ein Kunde erfolgreich gelöscht.")
            } else {
              this.message = success(ids.length + " Kunden erfolgreich gelöscht.")
            }
          }
        })
        await delay(100)
        i++
      }
    },
    async archiveCustomers(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('customer/editField', {
          id,
          uid: this.user.id,
          row: 'isArchived',
          newValue: true,
          entity: 'customers'
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.$store.dispatch('customer/getCustomers', {
              uid: this.user.id
            }).then(() => {
              this.loaded = true
              if (ids.length === 1) {
                this.message = success("Kunden erfolgreich archiviert.")
              } else {
                this.message = success(ids.length + " Kunden erfolgreich archiviert.")
              }
            }).catch((err) => {
              this.message = error(err)
            })
          }
        })
        await delay(100)
        i++
      }
    },
  }
}
</script>
<style>


</style>
